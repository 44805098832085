.phone-input-1 .react-international-phone-country-selector-dropdown {
  z-index: 9999;
  background-color: #fff;
  width: 396px;
}
.form-control {
  width: 100%;
  height: 56px !important;
}

.phone-input-1 .react-international-phone-country-selector-button {
  height: 56px;
}
.phone-warning {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
