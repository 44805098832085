.container-DataTable {
  width: 70%;
  height: 100%;
  padding: 20px;
  background-color: #1a1e26;
  color: #e2e8f0;
  font-family: "Poppins", sans-serif;
  margin-left: 350px; /* Added left margin */
  margin-top: 20px; /* Assuming mt-4 stands for margin-top: 20px */
  border: 0; /* Assuming border-0 stands for no border */
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03); /* Assuming shadow is something like this */
}

.header-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: transparent;
}
.header-DataTable h1 {
  margin: 0;
  font-size: 24px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
}
.custom-table th,
.custom-table td {
  border: 1px solid #374151;
  padding: 12px 15px;
  text-align: left;
}
.custom-table th {
  cursor: pointer;
  background-color: aliceblue;
  color: black;
}
.custom-table th:hover {
  background-color: #374151;
}
.custom-table th::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.custom-table th.asc::after {
  border-bottom: 4px solid #e2e8f0;
}
.custom-table th.desc::after {
  border-top: 4px solid #e2e8f0;
}
.custom-table tbody tr:nth-child(even) {
  background-color: rgb(19, 18, 18);
}
.custom-table tbody tr:hover {
  background-color: #1e2023;
}
.footer-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: transparent; /* Transparent background */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-inner h2 {
  margin-bottom: 15px;
}

.popup-inner p {
  margin-bottom: 20px;
}

.popup-inner button {
  margin: 5px;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 768px) {
  .container-DataTable {
    margin-left: 0px;
    width: 100%;

  }
  .custom-table th,
  .custom-table td {
    padding: 8px; /* Reduce padding */
  }

  .custom-table th:nth-child(n + 4),
  .custom-table td:nth-child(n + 4) {
    display: none; /* Example: hides columns from the 4th one onwards */
  }
}
.custom-table td {
  max-width: 150px; /* Limits width of cells */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
}
