.input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.ColumnNameInput.TextArea {
  padding-left: 30px;
}

.DraggableColumnNameInput.TextArea {
  padding-left: 40px;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 10px;
  cursor: pointer;
}
.search-icon:hover {
  color: #007bff;
}

.sidebar-search-icon {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
}

.dark-theme .sidebar-search-icon {
  color: white;
}

.sidebar-input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.searchContainer {
  position: relative;
  width: 100%; /* Adjusted to full width */
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 150px; /* Adjust if necessary */
  display: flex;
  justify-content: space-between; /* Aligns children to each end */
  align-items: center;
  gap: 20px;
}

.searchInput {
  font-size: 16px;
  width: fit-content;
}

.dark-theme .searchInput {
  background-color: #383838;
  color: white;
}

.searchContainer::before {
  content: "";
  position: absolute;
  left: 10px; /* Adjust based on desired position */
  top: 50%;
  transform: translateY(-50%);
  width: 16px; /* Icon size */
  height: 16px; /* Icon size */
  background-size: cover;
  pointer-events: none; /* Makes the icon non-interactive */
}

.search-instructions {
  white-space: nowrap; /* Keeps the text on one line */
}
