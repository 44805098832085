.paraghraph {
  font-size: 15px;
  text-align: left;
  font-weight: 400;
  line-height: 0.6cm;
  color: black;
  font-family: "Georgia", "Times", "Times New Roman", serif !important;
}

.author {
  font-weight: bold;
}

.arttical-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 4px solid;
  border-right: none;
  border-color: green;
  padding: 0 10px;
}

.link-RelatedArticles {
  text-decoration: none;
  color: black;
}

.link-RelatedArticles:hover {
  text-decoration: underline;
  color: "blue";
}
