.form-container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
}

.form-1 {
    padding: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;
    background: white;
    width: 50vh;
    border-radius: 8px;
}

.input-group-1 {
    margin-bottom: 15px;
}

.label-1 {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-1 {
    width: 92%;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    background-color: #4CAF50; /* Green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.submit-button:hover {
    background-color: #45a049;
}
.error {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
}
