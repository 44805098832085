.app-container {
  display: flex;
  flex-direction: column;
}

.container-DataTable {
  width: 70%;
  min-width: 300px; /* Minimum width to maintain layout structure */
  height: auto; /* Adjust height as necessary */
  padding: 20px;
  background-color: #0f172a;
  color: #e2e8f0;
  font-family: "Poppins", sans-serif;
  margin-left: 350px; /* Adjusted left margin */
  margin-top: 20px;
  border: 0;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.header-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: transparent;
}

.header-DataTable h1 {
  margin: 0;
  font-size: 24px;
}

.custom-table-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  /* Scrollbar customization */
  scrollbar-width: thick; /* For Firefox */
  scrollbar-color: #374151 #0f172a; /* For Firefox: thumb and track color */

  /* Webkit browsers like Chrome, Safari */
  &::-webkit-scrollbar {
    width: 16px; /* Makes the horizontal scrollbar thicker */
    height: 16px; /* Optional: for vertical scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #0f172a; /* Track color */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #374151; /* Thumb color */
    border-radius: 8px; /* Roundness of the scrollbar thumb */
    border: 3px solid #0f172a; /* Creates a border inside the track */
  }
}

.custom-table {
  width: 100%;
  min-width: 800px; /* Minimum width to ensure horizontal scroll on smaller screens */
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #374151;
  padding: 12px 15px;
  text-align: left;
  white-space: nowrap; /* Prevents text from wrapping */
}

.custom-table th {
  cursor: pointer;
  background-color: #111827;
  color: #e2e8f0;
}

.custom-table th:hover {
  background-color: #374151;
}

.custom-table th::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.custom-table th.asc::after {
  border-bottom: 4px solid #e2e8f0;
}

.custom-table th.desc::after {
  border-top: 4px solid #e2e8f0;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #111827;
}

.custom-table tbody tr:hover {
  background-color: #374151;
}

.footer-DataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background-color: transparent;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 768px) {
  .container-DataTable {
    width: 100%; /* Full width on small screens */
    margin-left: 0; /* Remove left margin */
    padding: 10px; /* Reduced padding */
  }

  .custom-table th,
  .custom-table td {
    padding: 8px; /* Smaller padding on small screens */
  }
}

@media (min-width: 769px) {
  .container-DataTable {
    margin-left: 250px; /* Adjust margin when navbar is visible */
  }
}
