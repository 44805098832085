.map-wrapper {
  height: 100vh;
  width: 100%;
  transform: rotate(180); /* Adjust the rotation angle as needed */
  overflow: hidden;
}

.map-wrapper .leaflet-container {
  height: 100vh;
  width: 100%;
  transform: rotate(180); /* Rotate map back to counter the wrapper rotation */
}

.leaflet-pane .leaflet-label {
  font-size: 1.5em !important; /* Increase font size */
  font-weight: bold !important; /* Make labels bold */
}

/* MapComponent.css */
.map-rotate-container {
  position: relative;
}

.all-gaza-button {
  position: absolute;
  top: 10px;
  right: 200px;
  padding: 10px 20px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.all-gaza-pane {
  position: absolute;
  top: 50px;
  right: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 1000;
}

.all-gaza-pane h2 {
  margin-top: 0;
}

.all-gaza-pane ul {
  list-style-type: none;
  padding-left: 0;
}

.all-gaza-pane li {
  margin-bottom: 10px;
}
